
import hljs from 'highlight.js/lib/core';

import php from 'highlight.js/lib/languages/php'
hljs.registerLanguage('php', php);
import sql from 'highlight.js/lib/languages/sql'
hljs.registerLanguage('sql', sql);
import ruby from 'highlight.js/lib/languages/ruby'
hljs.registerLanguage('ruby', ruby);
import erb from 'highlight.js/lib/languages/erb'
hljs.registerLanguage('erb', erb);
import bash from 'highlight.js/lib/languages/bash'
hljs.registerLanguage('bash', bash);
import javascript from 'highlight.js/lib/languages/javascript'
hljs.registerLanguage('javascript', javascript);

// import 'highlight.js/styles/github.css';
import 'highlight.js/styles/gruvbox-dark.css'

document.addEventListener('turbolinks:load', (event) => {
    document.querySelectorAll('pre').forEach((block) => {
        hljs.highlightBlock(block)
    })
})
