
import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

    destroy(event) {

        alert(this.id)

        this.element.remove()

        alert(this.id)

        Rails.ajax({
            url: `/todos/${this.id}`,
            type: "DELETE",
            success: function(data) {},
            error: function(data) {}
        })
    }

    toggle(event) {
        this.element.classList.toggle("completed");

        const data = new FormData();
        data.append("todo[completed]", event.target.checked);

        Rails.ajax({
            url: `/todos/${this.id}`,
            type: "PUT",
            data: data,
            success: function(data) {},
            error: function(data) {}
        })

        // this.railsUpdate(`${this.id}`, "todo[completed]", event.target.checked);
        // this.updateList();
    }

    // GETTERS AND SETTERS

    get id() {
        return this.data.get("id");
    }
}
